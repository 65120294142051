.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding: 10px;
  margin-top: 20px;
}

.students-table-container {
  width: 100%;
}

.students-table-container > tr {
  background-color: #f5f8fc;
  height: 50px;
}

.students-table-container > tr:nth-child(2n) {
  background-color: #ffffff;
}

.students-table-container > tr > th {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  color: #8f949f;
}

.students-table-container > tr > td {
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  min-width: 12%;
  max-width: 180px;
  text-align: center;
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

.table-list-count {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 700;
  color: #37464f;
}

.arrow-buttons-table {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #ecf0ff;
}

.right-btn {
  transform: rotate(180deg);
}

.table-btns {
  color: #456bf1;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400px;
}

@media screen and (min-width: 1270px) {
  .students-table-container > tr > th {
    font-size: 16px;
  }

  .students-table-container > tr > td {
    font-size: 16px;
  }
}
