@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.dashboard-periodwisebar-bg {
  width: 98%;
  padding: 10px;
  background: #ffffff;
  border: 0.25px solid #e2e9ff;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  position: relative;
  top: 90px;
  box-sizing: border-box;
  margin-bottom: 10px;
  z-index: 0;
  margin-left: 15px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.graph-filters {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.see-data-btn {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 700;
  color: #456bf1;
  font-style: normal;
  border: none;
  outline: none;
  border-radius: 40px;
  background: #f4f9ff;
  backdrop-filter: blur(40.5px);
}

.periodwisebar-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 14px;
  color: #606981;
  letter-spacing: 0.5px;
  font-style: normal;
  margin-left: 10px;
  width: 50%;
}

.filters {
  display: flex;
  align-items: center;
  width: 500px;
}

.line-chart {
  width: 55%;
}

.bar-chart-details {
  text-align: center;
  width: 45%;
  margin: 0;
  align-self: flex-start;
}

.barchart-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 700;
  color: #456bf1;
  font-style: normal;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 92% !important;
  outline: none;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-select-dash {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 700;
  color: #456bf1;
  font-style: normal;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 92% !important;
  outline: none;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-select:hover {
  background-color: rgb(231, 239, 250);
}

.filter-select-dash:hover {
  background-color: rgb(231, 239, 250);
}

.info-btns {
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 14px;
  color: #456bf1;
  background: #f4f9ff;
  backdrop-filter: blur(40.5px);
  border-radius: 40px;
  width: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.table-th {
  font-family: "Merriweather Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}

.table-td {
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.overlay-card {
  background-color: #fcfdff;
  width: 85%;
  max-width: 1800px;
  height: 100%;
  padding: 15px;
  overflow-y: auto;
}

.table-th {
  font-family: "Merriweather Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}

.table-td {
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.floatarr {
  transform: rotate(180deg);
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 0;
  margin: auto;
  overflow: auto;
  border: none;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

.table {
  width: 100%;
  display: flex;
  z-index: 1;
}

.overlay-tab {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #606981;
}

.overlay-tab-active {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  letter-spacing: 0.25px;
  color: #456bf1;
  text-decoration: underline;
  text-underline-offset: 10px;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

@media screen and (max-width: 1270px) {
  .bar-chart-details {
    width: 43%;
  }

  .overlay-card {
    width: 100%;
    max-width: 100%;
  }
}
