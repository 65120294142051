.dashboard-container {
  position: relative;
  top: 90px;
  padding: 4px;
  padding-left: 5px;
  display: flex;
  width: 98%;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
}

.empty-view {
  width: 100%;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

.metric-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  overflow-x: scroll;
  flex-wrap: nowrap;
  padding-right: 10%;
  padding-left: 20px;
}

.metric-ul > li {
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  min-width: 60px;
  height: 45px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.sub-metric-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  padding-left: 40px;
  background-color: #f8f9fe;
  width: 1120px;
}

.sub-metric-ul > li {
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-right: 40px;
  cursor: pointer;
}

.floatarr {
  transform: rotate(180deg);
}

.ok-btn {
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  background: #456bf1;
  border-radius: 40px;
  width: 140px;
  height: 40px;
  margin-top: 40px;
}

.uploaded-view {
  width: 100%;
  color: #000;
  text-align: center;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}

.metric-qstn {
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.20000000298023224px;
  color: #41475e;
  width: 80%;
}

.qstn-weightage {
  font-family: Lato;
  font-size: 16px;
  font-weight: 800;
  color: #456bf1;
  background-color: #f1f8fe;
  padding: 10px;
  padding-left: 15px;
  padding-right: 10px;
  border-radius: 13px;
  margin-left: 30px;
}

.metric-status {
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #606981;
  padding: 10px;
  border-radius: 13px;
  min-width: fit-content;
  margin-right: 10px;
}

.export-btn {
  font-size: 28px;
  font-weight: 900;
  color: #1976d2;
  padding: 10px;
}

.submit-btn {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  background: #456bf1;
  border-radius: 40px;
  align-self: flex-end;
  width: 140px;
  height: 40px;
  margin: 10px;
  margin-top: 40px;
}

.reject-btn {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  background: #dc3545;
  border-radius: 40px;
  align-self: flex-end;
  width: 140px;
  height: 40px;
  margin: 10px;
  margin-top: 40px;
}

.approve-btn {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  background: #198754;
  border-radius: 40px;
  align-self: flex-end;
  width: 140px;
  height: 40px;
  margin: 10px;
  margin-top: 40px;
}
