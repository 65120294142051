@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  animation: example 0.2s linear;
}

.overlay-card {
  background-color: #ffffff;
  width: 88%;
  max-width: 1300px;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.dashboard-periodwisebar-bg {
  width: 100%;
  padding-left: 10px;
  background: #ffffff;
  position: relative;
  top: 270px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  z-index: 0;
  padding-bottom: 280px;
}

.total-courses {
  color: #606981;
  font-weight: 900;
  font-family: "Lato";
  font-size: 15px;
  padding-left: 10px;
}

.info-btns {
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 14px;
  color: #456bf1;
  background: #f4f9ff;
  backdrop-filter: blur(40.5px);
  border-radius: 40px;
  width: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.courses-list {
  align-self: center;
}

.students-bg {
  width: 1130px;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-right: 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #f8f9fe;
}

.students-filters {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.filters-title {
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-weight: 700;
  color: #37464f;
  margin-bottom: 6px;
  margin-left: 10px;
}

.filters {
  font-weight: 900;
  font-size: 16px;
  font-family: "Lato";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: 86% !important;
  outline: none;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 100px;
  height: 45px;
  background-color: #fff;
  box-shadow: 0px 2px 3px 2px #0000000a;
}

.filters:hover {
  background-color: rgb(231, 239, 250);
}

.batches-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

.batches-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.sem-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}

.sem-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.batches-ul-active {
  background-color: #456bf1;
  color: #ffffff;
}

.sem-filter > li {
  width: 35px;
  padding: 5px;
}

.arrow-buttons {
  box-shadow: 0px 2px 3px 2px #0000000a;
  width: 30px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}

.right-btn {
  transform: rotate(180deg);
}

@media screen and (max-width: 1270px) {
  .overlay-card {
    width: 100%;
  }
}

@keyframes example {
  from {
    width: 0vw;
  }

  to {
    width: 100vw;
  }
}

.overlay-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #606981;
  letter-spacing: 0.5px;
}

.overlay-tab {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #606981;
}

.overlay-tab-active {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  letter-spacing: 0.25px;
  color: #456bf1;
  text-decoration: underline;
}

.search-input-container {
  box-shadow: 0px 2px 3px 2px #0000000a;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  border-color: #e2e9ff;
  width: 440px;
  height: 40px;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 20.1px;
  text-align: left;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 40px;
}

.search-input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  margin-left: 15px;
  margin-right: 10px;
}

.search-input::placeholder {
  opacity: 0.5;
}

.vertical-line {
  border: 1px solid #e2e9ff;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.search-input-title {
  font-family: "Lato";
  font-weight: 700;
  text-align: left;
  color: #707c97;
  padding-left: 10px;
  padding-right: 10px;
}

.list-grid-btns {
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 14px;
  color: #456bf1;
  background: #f4f9ff;
  backdrop-filter: blur(40.5px);
  border-radius: 40px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding-top: 20px;
  margin-top: 10px;
  border-radius: 12px;
}

.courses-table-container {
  width: 100%;
}

.courses-table-container tbody tr {
  background-color: #f5f8fc;
  height: 50px;
}

.courses-table-container tbody tr:nth-child(2n) {
  background-color: #ffffff;
}

.courses-table-container thead tr th {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  color: #8f949f;
  padding-bottom: 15px;
}

.courses-table-container tbody td {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  min-width: 12%;
  max-width: 180px;
  text-align: center;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.courses-table-container tbody td:first-child {
  font-family: "Merriweather Sans";
}

.courses-table-container tbody tr:last-child > td:first-child {
  border-bottom-left-radius: 12px;
}

.courses-table-container tbody tr:last-child > td:last-child {
  border-bottom-right-radius: 12px;
}
