.metric-bar-container {
  width: 100px;
  height: 22px;
  background-color: #e2e9ff;
  border-radius: 10px;
  margin-top: 5px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Lato";
  font-size: 12px;
  font-weight: 700;
  line-height: 11.52px;
  letter-spacing: 0.25px;
}

.metric-bar {
  background-color: #6276e5;
  height: 22px;
  border-radius: 10px;
}

.metric-table-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
  text-wrap: nowrap;
}

.metric-table-name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  text-wrap: nowrap;
  padding-right: 15px;
  text-align: center !important;
}

.metric-table-date {
  min-width: 115px;
  max-width: 115px;
  text-align: left;
  padding: 5px !important;
}

@media screen and (min-width: 1400px) {
  .metric-bar-container {
    margin-left: 20px;
  }
}
