.home {
  width: 100vw !important;
  max-width: 1800px;
}

.fb-container {
  width: 98%;
  margin-left: 15px;
  margin-right: 13px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  background: #f7f9ff;
  position: relative;
  top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-height: 100%;
  overflow: hidden;
}

.fb-form-container {
  width: 75%;
  border-radius: 18px;
  background: #fff;
  box-shadow: 8px 10px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  max-height: 75%;
  overflow-x: hidden;
}

.fb-heading {
  color: #456bf1;
  font-family: "Merriweather Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.fb-desc {
  color: #8592b5;
  text-align: justify;
  font-family: "Merriweather Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-top: 20px;
}

.fb-label {
  color: #8f949f;
  font-family: "Merriweather Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 30px;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-top: 10px;
  outline: none;
  width: 100%;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.fb-submit-btn {
  width: 100%;
  max-width: 400px;
  border-radius: 3px;
  background: #1c60ff;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  height: 40px;
  margin-top: 25px;
}

.thanks-img {
  width: 300px;
  height: 200px;
}

.thanks-text {
  color: #456bf1;
  font-family: "Merriweather Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-top: 30px;
}

.thanks-desc {
  color: #8592b5;
  text-align: center;
  font-family: "Merriweather Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.25px;
  width: 601px;
  margin-top: 30px;
}

@media screen and (max-width: 1270px) {
  .fb-form-container {
    width: 80%;
  }
}
