@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.art-matrix-title {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  color: #606981;
  margin-bottom: 15px;
}

.table-th {
  font-family: "Merriweather Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}

.table-td {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.floatarr {
  transform: rotate(180deg);
}

.table {
  width: 100%;
  display: flex;
  z-index: 1;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 0;
  margin: auto;
  overflow: auto;
  border: #456bf1 1px solid;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table-scroll table {
  width: 100%;
  min-width: 100%;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th,
.table-scroll td {
  border: #456bf1 1px solid;
  vertical-align: top;
}
.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 4;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

.marks-dist-table > tr > th {
  background-color: #eff3ff;
  font-family: "Merriweather Sans";
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  color: #040c58;
}

.marks-dist-table > tr > td {
  background-color: #fff;
  font-family: "Lato";
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  color: #606981;
}

@media screen and (min-width: 1270px) {
  .marks-dist-table > tr > th {
    font-size: 15px;
  }

  .marks-dist-table > tr > td {
    font-size: 15px;
  }
}
