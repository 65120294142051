@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.home {
  width: 100vw !important;
  max-width: 1800px;
}

.account-container {
  width: 98%;
  margin-left: 25px;
  margin-right: 13px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  background: #ffffff;
  position: relative;
  top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.info-container {
  padding: 30px;
  width: 100%;
  border-radius: 8.411px;
  border: 0.2px solid #b8c4e7;
}

.overlay-tab {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #606981;
}

.overlay-tab-active {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  letter-spacing: 0.25px;
  color: #456bf1;
  text-decoration: underline;
}

.label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8f949f;
  margin-top: 10px;
}

.filter-select-wght {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 14px) 15px !important;
  outline: none;
  width: 50%;
  height: 40px;
}

.filter-select-wght::placeholder {
  opacity: 0.5;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 10px;
  outline: none;
  width: 50%;
  height: 40px;
}

.filter-txt::placeholder {
  opacity: 0.5;
}
