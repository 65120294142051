.bg-containers {
  border: 1px solid #e2e9ff;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.mapped-qp-table {
  width: 100%;
}

.mapped-qp-table > tr {
  border: 1px solid #456bf1;
}

.mapped-qp-table tr th {
  border: 1px solid #456bf1;
  padding: 10px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #606981;
}

.mapped-qp-table tr td {
  border: 1px solid #456bf1;
  padding: 20px 20px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #040c58;
}

.co-select {
  background-color: #27ae7a;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 800;
  line-height: 33px;
  color: #ffffff;
  border-radius: 7px;
  width: 65px;
  height: 30px;
  outline: none;
  text-align: center;
  border-right: 5px solid transparent;
}

.bloom-select {
  width: 55px;
  color: #606981;
  font-size: 18px;
  font-family: "Merriweather Sans";
  font-weight: 500;
  line-height: 18px;
  appearance: none;
  background: none;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  padding: 6.5px;
  outline: none;
  text-align: center;
  align-self: center;
  background: #f9fbff;
}

.info-icon {
  font-size: 20px;
  color: #8f949f;
  margin-left: 4px;
  cursor: pointer;
}

.co-select > * {
  background-color: #fff;
  color: #456bf1;
}

.co-cell {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none !important;
}

.marks-input {
  border: 1.5px solid #f1f3f5;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  padding: 5px 10px;
  outline-width: 1px;
  outline-color: #456bf1;
  font-family: "Lato";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.5px;
  background: #f9fbff;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 60vw;
  max-width: 900px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -15px;
  bottom: auto;
  right: 90%;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.submit-button {
  padding: 6px 25px;
  border-radius: 40px;
  background: #456bf1;
  color: #fff;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
}

.back-button {
  padding: 6px 25px;
  border-radius: 40px;
  background: #f5f8fc;
  color: #456bf1;
  border: 1px solid #e2e9ff;
  margin-right: 30px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
}

.download-button {
  border: 1px solid #456bf1;
  padding: 6px 25px;
  border-radius: 40px;
  background: #f5f8fc;
  color: #456bf1;
  margin-right: 30px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
}

.template-ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.template-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-weight: 900;
  font-size: 14px;
  font-family: "Lato";
  outline: none;
  padding: 10px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 2px #0000000a;
  border: 0.25px solid #e2e9ff;
  margin-right: 10px;
  cursor: pointer;
}

.template-ul-active {
  background-color: #456bf1;
  color: #ffffff;
}
