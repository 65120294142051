@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.home {
  width: 100vw !important;
  max-width: 1800px;
}

.account-container {
  width: 100%;
  margin-left: 25px;
  margin-right: 13px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  background: #ffffff;
  position: relative;
  top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.account-dp {
  width: 110px;
  height: 110px;
  border-radius: 110px;
  object-fit: contain;
}

.profile {
  padding-left: 30px;
  padding-right: 30px;
  width: 750px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 8.411px;
  border: 0.2px solid #b8c4e7;
  background: #fff;
  box-shadow: 0px 2px 2.1027026176452637px 1.4018018245697021px
    rgba(0, 0, 0, 0.04);
}

.profile-name {
  color: #040c58;
  font-family: "Lato";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.profile-designation {
  color: #606981;
  font-family: "Lato";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 5px;
}

.profile-id {
  color: #606981;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.edit-btn {
  border-radius: 40px;
  background: #f4f9ff;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  backdrop-filter: blur(40.5px);
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.04));
  color: #456bf1;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  display: flex;
  align-items: center;
}

.info-container {
  padding: 30px;
  width: 750px;
  flex-shrink: 0;
  border-radius: 8.411px;
  border: 0.2px solid #b8c4e7;
  background: #fff;
  box-shadow: 0px 2px 2.1027026176452637px 1.4018018245697021px
    rgba(0, 0, 0, 0.04);
}

.info-main-heading {
  color: #040c58;
  font-family: "Lato";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
}

.label {
  color: #8f949f;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.462px;
  letter-spacing: -0.106px;
}

.info-input {
  border-radius: 8.41px;
  border: 1px solid #9c9c9c;
  width: 300px;
  height: 44px;
  color: #606981;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  padding: 10px;
  outline: none;
}

.info-logout-btn {
  width: 100px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 40px;
  background: #3d65f4;
  backdrop-filter: blur(40.5px);
  color: #fff;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  margin-right: 20px;
}

.info-change-pwd-btn {
  width: 190px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 40px;
  background: #f4f9ff;
  backdrop-filter: blur(40.5px);
  color: #456bf1;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.462px;
  letter-spacing: -0.106px;
  margin-right: 30px;
}

.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.25px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.hide-icon {
  font-size: 22px;
}

.input-bg {
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333333;
  width: 300px;
  height: 44px;
  border-radius: 8.41px;
  border: 1px solid #9c9c9c;
  padding-right: 10px;
  margin-top: 6px;
}

.input {
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  width: 300px;
  height: 44px;
  color: #606981;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  align-items: center;
  padding-right: 8px;
  font-family: "Lato";
}
