.stu-details-container {
  display: flex;
  flex-direction: row;
  margin: 100px 20px 0px 15px;
  padding: 20px 20px;
  width: 98%;
  top: 105px;
  left: 229px;
  border-radius: 12px 12px 12px 12px;
  border: 0.2px 0px 0px 0px;
  background: #ffffff;
  border: 0.2px solid #b8c4e7;
  box-shadow: 0px 2px 2.1px 1.4px #0000000a;
}

.stu-img {
  width: 106px;
  height: 106px;
  top: 129px;
  left: 361px;
}

.stu-profile-details {
  width: 90%;
  margin: 10px 0px 0px 30px;
}

.stu-name-edit {
  display: flex;
  justify-content: space-between;
}

.stu-name {
  font-family: Lato;
  font-size: 26px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #040c58;
  width: 50%;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stu-edit-del {
  display: flex;
  background: #f4f9ff;
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
}

.stu-edit {
  display: flex;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 24.46px;
  letter-spacing: -0.10599999874830246px;
  text-align: left;
  color: #456bf1;
  padding-right: 20px;
  cursor: pointer;
}

.stu-del {
  display: flex;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 24.46px;
  letter-spacing: -0.10599999874830246px;
  text-align: left;
  color: #e35050;
  padding: 0px 10px;
  cursor: pointer;
}

.ver-line {
  border: 0.5px solid #b8c4e7;
}

.stu-details {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.stu-quick-act {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f9ff;
  border-radius: 20px;
  margin: 25px 0px 15px 0px;
  padding: 5px 20px;
}

.stu-act {
  display: flex;
}

.stu-span-head {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 11.52px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #8f949f;
}

.stu-quick-span {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 11.52px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #8f949f;
}

.stu-span {
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 24.46px;
  text-align: left;
  color: #456bf1;
  padding-left: 7px;
}

.stu-p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #606981;
}

.stu-details-span {
  font-family: Lato;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #606981;
  max-width: 200px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stu-logo {
  width: 20%;
}

.stu-academic-container {
  margin: 20px 20px 0px 15px;
  padding: 20px 0px;
  width: 98%;
  top: 330px;
  left: 229px;
  border-radius: 12px 12px 12px 12px;
  border: 1px 0px 0px 0px;
  background: #ffffff;
  border: 1px solid #e2e9ff;
  box-shadow: 0px 2px 3px 2px #0000000a;
}

.academic-container {
  border-bottom: 1px solid #b8c4e7;
  padding-left: 20px;
}

.academic-button {
  padding: 0px 10px 15px 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 11.52px;
  letter-spacing: 0.5px;
  text-align: center;
}

.sem-fail-sub-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
}

.span-sem {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #37464f;
}

.fail-sub-container {
  border: 1px solid #e35050;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #e35050;
  background: #fff2f2;
  padding: 5px 10px;
  height: 30px;
  border-radius: 9px;
}

.sem-button-active {
  width: 35px;
  height: 44px;
  top: 442px;
  left: 418px;
  gap: 0px;
  background-color: #456bf1;
  color: #e2e9ff;
  border-radius: 12px 12px 12px 12px;
  border: 0.25px 0.25px 0.25px 0.25px;
  border: 0.25px solid #e2e9ff;
  font-weight: 600;
  box-shadow: 0px 2px 3px 2px #0000000a;
  margin: 8px 8px 0px 0px;
}

.sem-button {
  width: 40px;
  height: 44px;
  border-radius: 12px 12px 12px 12px;
  border: 0.25px 0.25px 0.25px 0.25px;
  background: #ffffff;
  border: 0.25px solid #e2e9ff;
  color: #606981;
  font-weight: 600;
  box-shadow: 0px 2px 3px 2px #0000000a;
  margin: 8px 8px 0px 0px;
  padding-left: 2px;
  padding-right: 2px;
}

.sub-marks-container {
  width: 90%;
  padding: 20px 30px;
  margin: 25px;
}

.sub-head-container {
  display: flex;
}

.sub-head {
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.25px;
  text-align: center;
  width: 15%;
}

.sub-head:first-child {
  width: 28%;
  text-align: left;
}

.labels {
  color: #8f949f;
  font-size: 14px;
  font-family: "Merriweather Sans";
  line-height: 18px;
  margin-bottom: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay-card {
  background-color: #fcfdff;
  width: 70%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  right: 0;
  position: absolute;
}

.overlay-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #606981;
}

.filter-txt {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 0px;
  outline: none;
  min-width: 250px;
  max-width: 250px;
}

.filter-select {
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  color: #606981;
  font-style: normal;
  background: #f9fbff;
  border: 1.5px solid #f1f3f5;
  border-radius: 5px;
  margin-bottom: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/public/assets/filterArrow.svg") no-repeat;
  background-position: calc(100% - 20px) 17px;
  outline: none;
  min-width: 250px;
  max-width: 250px;
}

.filter-select::placeholder {
  opacity: 0.5;
}

.filter-select:invalid {
  color: rgba(153, 153, 153, 0.7);
}

.filter-select:focus {
  color: #606981;
  opacity: 1;
}

.filter-txt::placeholder {
  opacity: 0.5;
}

.button {
  display: flex;
  justify-content: center;
  padding: 10px 18px;
  border-radius: 40px;
  background: #456bf1;
  color: #fff;
  font-family: "Merriweather Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  width: 140px;
  margin-right: 20px;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
}

@media screen and (max-width: 1270px) {
  .overlay-card {
    width: 100%;
  }

  .academic-button {
    padding: 0px 5px 15px 5px;
    font-size: 13px;
  }
}
