@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");

.nav-bar {
  width: 100%;
  height: 12vh;
  max-height: 80px;
  max-width: 1800px;
  position: fixed;
  padding-right: 20px;
  z-index: 10;
  background-color: #ffffff;
}

.rubrix-logo-container {
  height: 89px;
  margin-left: 5px;
  width: 12%;
}

.rubrix-icon {
  width: 35%;
  height: 30px;
}

.fatcat-title {
  width: 65%;
}

.tab-bar {
  width: 80%;
  max-width: 400px;
  height: 40px;
  border-radius: 40px;
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  color: #606981;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: 0.5px;
  margin-left: 40px;
}

.arrows-container {
  width: 100px;
  height: 32px;
}

.arrow-bg {
  background-color: #ecf0ff;
  border-radius: 32px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bell-icon {
  width: 20px;
}

.count {
  font-size: 11px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-weight: 500;
}

.dropdown {
  width: 267px;
  height: 236px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.5px solid #456bf1;
  background: #fff;
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 15px;
  top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.li-text {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-left: 20px;
  color: #606981;
}

.li-items {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
}

.logout-btn {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #f86e7f;
  margin-bottom: 10px;
}

.profile {
  width: 35px;
  height: 35px;
}

@media screen and (min-width: 1270px) {
  .tab-bar {
    width: 70%;
    height: 44px;
    font-size: 18px;
  }

  .arrows-container {
    height: 32px;
  }

  .arrow-bg {
    border-radius: 32px;
    height: 32px;
    width: 32px;
  }

  .input-el-container {
    height: 44px;
  }

  .count {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }

  .dropdown {
    height: 236px;
  }

  .li-text {
    font-size: 16px;
  }

  .li-items {
    margin-bottom: 25px;
  }

  .profile {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 1440px) {
  .tab-bar {
    margin-left: 50px;
  }
}
