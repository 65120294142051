.attainments-table {
  border: 1px solid #456bf1;
  border-radius: 5px;
  width: 100%;
  font-family: "Lato";
  padding-right: 0;
  margin-top: 20px;
}

.table {
  width: 100%;
}

.table-scroll {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: auto;
}

.table-scroll table {
  width: 150%;
  min-width: 175%;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

.table-th {
  font-family: "Merriweather Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  width: 10px;
}

.table-td {
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  width: 10px;
}

.attainments-table > thead > tr > th {
  border: 0.5px solid #456bf1;
  padding: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #040c58;
  background-color: #eff3ff;
  min-width: 35px !important;
  max-width: 35px !important;
}

.attainments-table > thead > tr > th:first-child {
  min-width: 100px !important;
  max-width: 100px !important;
}

.attainments-table > tbody > tr > td {
  border: 0.5px solid #456bf1;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  min-width: 35px !important;
  max-width: 35px !important;
}

.attainments-table > tbody > tr > td:first-child {
  min-width: 100px !important;
  max-width: 100px !important;
}

.attainments-table > tbody > tr > .course-info {
  min-width: 100px !important;
  max-width: 100px !important;
}

.plus-btn {
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #456bf1;
  width: 60px;
  border-radius: 12px;
  padding: 2px;
  margin-top: 10px;
  margin-left: 10px;
  align-self: flex-end;
  z-index: 10;
}

.save-btn {
  border: 1px solid #e2e9ff;
  background-color: #f5f8fc;
  font-family: "Lato";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  color: #456bf1;
  width: 130px;
  border-radius: 12px;
  padding: 5px;
  margin-top: 10px;
  align-self: flex-end;
}

.label-box {
  width: 90%;
  color: #606981;
  font-size: 14px;
  font-family: "Merriweather Sans";
  font-weight: 300;
  line-height: 18px;
  border: 1.5px solid #e8e9ea;
  padding: 6.5px;
  outline: none;
  text-align: center;
  height: 34px !important;
}
