@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.course-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-style: normal;
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #eff3f9;
  border-radius: 5px;
  margin-top: 5px;
}

.progress-bar {
  background-color: #456bf1;
  height: 8px;
  border-radius: 10px;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  width: 100%;
  height: 100%;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
}

.course-name {
  width: 150px;
  text-align: center;
}

.batch {
  font-family: "Merriweather Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.modal {
  background-color: #ffffff;
  position: relative;
  width: 500px;
  min-height: 300px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.modal-container {
  overflow-y: auto;
  width: 450px;
  min-height: 150px;
  max-height: 470px;
}

.course-edit-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: solid #456bf1 1px;
  border-radius: 7px;
  box-shadow: 0px 7px 10px 0px #0000001a;
  padding: 10px;
  padding-left: 20px;
  background-color: #ffffff;
  width: 150px;
  height: 80px;
  position: absolute;
  left: 53%;
  top: 12%;
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  color: #040c58;
}

.overlay-labels-co {
  color: #456bf1;
  font-size: 11px;
  font-family: "Merriweather Sans";
  line-height: 22px;
  border-radius: 0px 0px 0px 10px;
  border: 1px solid #e2e9ff;
}

.overlay-labels-co-y {
  color: #27ae7a;
  font-size: 11px;
  font-family: "Merriweather Sans";
  line-height: 22px;
  border-radius: 0px 0px 0px 10px;
  border: 1px solid #e2e9ff;
}

.overlay-labels-qp {
  color: #456bf1;
  font-size: 11px;
  font-family: "Merriweather Sans";
  line-height: 22px;
  border-radius: 0px;
  border: 1px solid #e2e9ff;
  border-right: none;
  border-left: none;
}

.overlay-labels-fb {
  color: #456bf1;
  font-size: 11px;
  font-family: "Merriweather Sans";
  line-height: 22px;
  border: none;
  border-radius: 0px 0px 10px 0px;
  border: 1px solid #e2e9ff;
}
.overlay-labels-fb-y {
  color: #27ae7a;
  font-size: 11px;
  font-family: "Merriweather Sans";
  line-height: 22px;
  border: none;
  border-radius: 0px 0px 10px 0px;
  border: 1px solid #e2e9ff;
}

@media screen and (min-width: 1270px) {
  .course-title {
    font-family: "Lato";
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-style: normal;
  }

  .progress-bar-container {
    width: 100%;
    height: 8px;
    background-color: #eff3f9;
    border-radius: 5px;
    margin-top: 5px;
  }

  .progress-bar {
    background-color: #456bf1;
    height: 8px;
    border-radius: 10px;
  }

  .overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table {
    width: 100%;
    height: 100%;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
  }

  .course-name {
    width: 150px;
    text-align: center;
  }

  .batch {
    font-family: "Merriweather Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  .modal {
    background-color: #ffffff;
    position: relative;
    width: 500px;
    min-height: 300px;
    max-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
  }

  .modal-container {
    overflow-y: auto;
    width: 450px;
    min-height: 150px;
    max-height: 470px;
  }

  .course-edit-popup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: solid #456bf1 1px;
    border-radius: 7px;
    box-shadow: 0px 7px 10px 0px #0000001a;
    padding: 10px;
    padding-left: 20px;
    background-color: #ffffff;
    width: 150px;
    height: 80px;
    position: absolute;
    left: 53%;
    top: 12%;
    font-family: "Merriweather Sans";
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #040c58;
  }

  .overlay-labels-co {
    color: #456bf1;
    font-size: 12px;
    font-family: "Merriweather Sans";
    line-height: 22px;
    border-radius: 0px 0px 0px 10px;
    border: 1px solid #e2e9ff;
  }

  .overlay-labels-co-y {
    color: #27ae7a;
    font-size: 12px;
    font-family: "Merriweather Sans";
    line-height: 22px;
    border-radius: 0px 0px 0px 10px;
    border: 1px solid #e2e9ff;
  }

  .overlay-labels-qp {
    color: #456bf1;
    font-size: 12px;
    font-family: "Merriweather Sans";
    line-height: 22px;
    border-radius: 0px;
    border: 1px solid #e2e9ff;
    border-right: none;
    border-left: none;
  }

  .overlay-labels-fb {
    color: #456bf1;
    font-size: 12px;
    font-family: "Merriweather Sans";
    line-height: 22px;
    border: none;
    border-radius: 0px 0px 10px 0px;
    border: 1px solid #e2e9ff;
  }
  .overlay-labels-fb-y {
    color: #27ae7a;
    font-size: 12px;
    font-family: "Merriweather Sans";
    line-height: 22px;
    border: none;
    border-radius: 0px 0px 10px 0px;
    border: 1px solid #e2e9ff;
  }
}
