.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #fff5ea;
  border-radius: 5px;
  margin-top: 5px;
}

.progress-bar {
  background-color: #f89e4b;
  height: 8px;
  border-radius: 10px;
}

.metric-progress-desc {
  font-family: Merriweather Sans;
  font-size: 12px;
  font-weight: 400;
  color: #606981;
  width: 48%;
}

.metric-progress-percent {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #f89e4b;
  background-color: #fff5ea;
  padding: 10px;
  border-radius: 8px;
  margin-right: 10px;
}

.metrics-btn {
  font-family: "Merriweather Sans";
  font-size: 12px;
  font-weight: 700;
  color: #456bf1;
  height: 28px;
  border-radius: 0px 0px 12.5px 12px;
  border: none;
  border-top: 1px solid #e2e9ff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
}
