@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.side-bar {
  width: 12%;
  max-width: 220px;
  position: fixed;
  box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
  top: 0;
  bottom: 0;
  padding-top: 80px;
  z-index: 2;
}

.sidebar-item-active {
  background-color: #3d65f4;
  width: 100%;
  box-shadow: 0px 7.94872px 15.8974px rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.sidebar-item {
  background-color: transparent;
  width: 100%;
  box-shadow: 0px 7.94872px 15.8974px rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.sidebar-text-active {
  font-weight: 700;
  font-style: normal;
  font-family: "Lato";
  line-height: 16px;
  font-size: 12px;
  width: 80px;
  height: 16px;
  margin-left: 8px;
}

.sidebar-text {
  font-weight: 700;
  font-style: normal;
  font-family: "Lato";
  line-height: 16px;
  font-size: 12px;
  width: 70px;
  color: #606981;
  margin-left: 8px;
}

.feed-back-icon-container {
  background-color: #3d65f4;
  border-radius: 50%;
  padding: 4px;
}

.feed-container {
  position: absolute;
  bottom: 20px;
  left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  cursor: pointer;
  opacity: 0.5;
}

.feed-container-active {
  opacity: 1;
}

.fb-text {
  color: #000;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.25px;
  margin-left: 12px;
}

.side-bar-icons {
  width: 16%;
  height: 16px;
}

@media screen and (min-width: 1270px) {
  .feed-back-icon-container {
    padding: 8px;
  }

  .feed-container {
    left: 10px;
  }

  .side-bar-icons {
    width: 18%;
    height: 18px;
  }

  .sidebar-text-active {
    font-size: 14px;
  }

  .sidebar-text {
    font-size: 14px;
  }

  .fb-text {
    font-size: 14px;
  }
}
