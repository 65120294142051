@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.dashboard-periodwisebar-bg {
  width: 99%;
  margin-left: 15px;
  margin-right: 13px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 100px;
  background: #ffffff;
  position: relative;
  top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.learn-img {
  background-image: url("/public/assets/learn.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
