.marks-table-container {
  max-height: 400px;
  overflow: auto;
  background-color: #f7f9ff;
  border-bottom: 1px solid #e2e9ff;
  width: 98%;
}

.marks-table {
  border-radius: 3px;
  border-collapse: separate;
  border-spacing: 0px;
  text-align: center;
  font-family: "Lato";
  letter-spacing: 0.36490127444267273px;
  background-color: transparent;
}

.marks-table > tr > th {
  background-color: #9fb2f6;
  border: 0.73px solid #9fb2f6;
  border-radius: 3px !important;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  min-width: 50px;
}

.marks-table > tr > td {
  border: 0.73px solid #9fb2f6;
  border-radius: 3px !important;
  width: 60px;
  padding: 5px;
  font-size: 14px;
  min-width: 50px;
}

.marks-table > tbody > tr > th {
  border: 0.73px solid #dadada;
  border-radius: 3px !important;
  color: #2c2828;
  font-family: "Lato";
  width: 170px;
  padding: 5px;
  font-size: 13px;
  min-width: 50px;
}

.marks-table > tbody > tr > td {
  border: 0.73px solid #dadada;
  border-radius: 3px !important;
  color: #606981;
  width: 60px;
  padding: 5px;
  font-size: 13px;
  min-width: 50px;
}

.marks-table-total {
  background-color: #3d65f4 !important;
  font-weight: 800 !important;
  padding: 10px;
  font-size: 14px;
}

.marks-table-total-value {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 16.06px;
  letter-spacing: 0.36490127444267273px;
  background-color: #e2e9ff;
  color: #040c58 !important;
}

.threshold-table {
  min-width: min-content;
  max-width: 83% !important;
  text-align: center;
  border-radius: 3px;
  border-collapse: collapse;
  border: 0.5px solid #456bf1;
}

.threshold-table > tr > th {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 900;
  line-height: 11.52px;
  letter-spacing: 0.25px;
  color: #040c58;
  border: 0.5px solid #456bf1;
  padding: 10px;
}

.threshold-table > tbody > tr > th {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: #040c58;
  text-wrap: nowrap;
  border: 0.5px solid #456bf1;
  padding: 10px;
}

.threshold-table > tbody > tr > td {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  line-height: 11.52px;
  letter-spacing: 0.25px;
  color: #000000;
  border: 0.5px solid #456bf1;
  padding: 10px;
}

.threshold-table > tbody > tr > td > input {
  font-size: 14px;
  font-weight: 600;
  color: #606981;
  border: 1.5px solid #d9e0ff;
  width: 50%;
  text-align: center;
  padding: 5px;
  outline-color: #3d65f4;
  border-radius: 5px;
  margin-right: 2px;
}

.std-atd-info {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  line-height: 11.52px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #000000;
}

.std-atd-info > p {
  margin-top: 15px;
}

.threshold-btn {
  font-family: "Lato";
  font-size: 15px;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  background-color: #456bf1;
  border-radius: 40px;
  padding: 8px;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 1px;
  position: absolute;
  bottom: 25px;
  margin-left: 30px;
}

.empty-view {
  width: 300px;
  height: 61px;
  flex-shrink: 0;
  color: #9fb2f6;
  text-align: center;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  text-transform: capitalize;
}

.floatarr {
  transform: rotate(180deg);
}
